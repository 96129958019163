<template>
  <div>
    <GroupSummary
      v-if="group"
      :current-group="group"
      :current-packages="packages"
      :unavailable-packages="unavailablePackages"
      :current-accommodations="accommodations"
      :show-registration-link="showRegistrationLink"
    />
  </div>
</template>

<script>
import GroupSummary from "@/views/groups/group/GroupSummary/GroupSummary.vue";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";

export default {
  components: {
    GroupSummary,
  },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    packages() {
      return this.$store.state["app-groups"]?.packages ?? [];
    },
    accommodations() {
        return this.$store.state["app-groups"]?.accommodations ?? [];
    },
    unavailablePackages() {
      let backorderPackages = [];

      this.packages.forEach((pkg) => {
        let hasRoomsLeft = true;
        let hasTransportationLeft = true;

        pkg.rooms.forEach((room) => {
          if (room.room_remaining_inventory.remaining_places <= 0) {
            hasRoomsLeft = false;
          }
        });

        if (
          pkg.transportation.transportation_remaining_seats.remaining_seats <= 0
        ) {
          hasTransportationLeft = false;
        }

        if (!hasRoomsLeft || !hasTransportationLeft) {  
          backorderPackages.push(pkg.id);
        }
      });

      return backorderPackages;
    },
    showRegistrationLink() {
      try {
        return (
          this.$store.state[AUTH_STORE_NAMESPACE].orgSettings
            ?.public_url_enable === true
        );
      } catch (e) { 
        return false;
      }
    },
  },
};
</script>
